<template>
  <div class="ActivityWorkflow">
    <v-data-table
      class="ActivityWorkflow__data-table elevation-0"
      :items="rows"
      :headers="headers"
      single-expand
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      :loading="loading"
      @click:row="goToHistoryPage"
      @update:page="(val) => updateQuery(val, 'page')"
      @update:items-per-page="(val) => updateQuery(val, 'limit')"
      :server-items-length="activityTotalNumber"
    >
      <template v-slot:item.start="{ item }">
        <span>{{ formatDate(item.start) }}</span>
      </template>
      <template v-slot:item.finish="{ item }">
        <span>{{ formatDate(item.finish) }}</span>
      </template>
      <template v-slot:item.duration="{ item }">
        <span>{{ getDuration(item.start, item.finish) }}</span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <v-card tile flat>
            <v-card-title>{{ item.AutomationWorkflow.name }}</v-card-title>
            <v-card-subtitle>Errors statistics</v-card-subtitle>
            <v-card-text>
              <p v-if="!item.stats">Oops, no stats. Get stats first</p>
              <v-treeview v-else :items="makeStatsArr(item.stats.report)">
                  <template #label="{ item: treeItem }">
                      <span @click="goToHistoryPage(item, treeItem)">{{treeItem.name}}</span>
                  </template>
              </v-treeview>
            </v-card-text>
            <v-card-actions>
              <v-btn
                :loading="statsLoading"
                :disabled="statsLoading"
                text
                color="primary"
                small
                v-if="item.stats === null"
                @click="getStat(item)"
              >
                Estimate statistics
              </v-btn>
            </v-card-actions>
          </v-card>
        </td>
      </template>
        <template v-slot:item.actions="{ item }">
            <v-btn
                    color="primary"
                    outlined
                    small
                    v-if="item.workflow_status === workflowStatus.PAUSED"
                    @click.stop="resumeAndDeleteWorkflow(item.id)"
            >
                RESUME
            </v-btn>
        </template>
    </v-data-table>
  </div>
</template>

<script>
import * as moment from 'moment-timezone';
import {createNamespacedHelpers} from "vuex";
import {workflowStatus} from "@/util/workflow-statuses";

const {
    mapActions: workflowHistoryActions,
    mapMutations: workflowHistoryMutation,
} = createNamespacedHelpers('workflows/history');

const {
  mapGetters: activityGetters,
  mapActions: activityActions,
  mapMutations: activityMutations,
} = createNamespacedHelpers('activity');

const {
    mapMutations: customerMutations,
    mapGetters: customerGetters,
} = createNamespacedHelpers('customer');

export default {
  name: 'ActivityWorkflow',
    computed: {
        workflowStatus() {
            return workflowStatus
        },
        ...customerGetters({
            customers: 'CUSTOMERS'
        }),
        ...activityGetters({
          activityTotalNumber: 'GET_ACTIVITY_TOTAL_NUMBER',
        })
    },
  props: {
    queryData:  {
      dateRange: [],
      localTZ: 'UTC',
      search: '',
    },
    rows: {
      type: Array,
      default: () => ([]),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    statsLoading: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      limit: 10,
      expanded: [],
      headers: [
        { text: 'Workflow name', value: 'AutomationWorkflow.name', sortable: false, align: 'start' },
        { text: 'Start', value: 'start' },
        { text: 'Finish', value: 'finish' },
        { text: 'Duration', value: 'duration' },
        { text: 'Total tasks', value: 'stats.tasks_total' },
        { text: 'Successfull tasks', value: 'stats.tasks_success' },
        { text: 'Task errors', value: 'stats.tasks_failed' },
        { text: 'Success rate', value: 'stats.success_rate' },
        { text: 'Status', value: 'workflow_status' },
        { text: 'Actions', value: 'actions', key: 'actions', sortable: false }
      ],
    }
  },
  methods: {
      ...workflowHistoryActions([
          'resumeWorkflow',
      ]),
      ...workflowHistoryMutation({
          setWorkflowHistoryItem: 'SET_WORKFLOW_HISTORY_ITEM'
      }),
      ...customerMutations({
          setCustomer: 'SET_SELECTED_CUSTOMER'
      }),
      ...activityActions(['getActivity']),
    ...activityMutations({
      setActivityPage: 'SET_ACTIVITY_PAGE'
    }),
    formatDate(date) {
      if (!date) return '';
      else return moment(date).tz('UTC').format('YYYY-MM-DD HH:mm');
    },
    getDuration(start, finish) {
      if (!start || !finish) return "-";
      else {
        const diff_ms = moment(finish).diff(start);
        return moment.utc(diff_ms).format("HH:mm:ss");
      }
    },
    updateQuery(val, type) {
      const from = moment(this.queryData.dateRange[0]).format('YYYY-MM-DD');
      const to = moment(this.queryData.dateRange[1]).format('YYYY-MM-DD');

      if (!from || !to) return;

      if(type === 'page') {
        this.setActivityPage(val);
      }

      if(type === 'limit') {
        this.limit = val;
      }

      this.getActivity({
        from: from,
        to: to,
        tz: this.localTZ,
        report_type: 'workflows',
        page: val - 1,
        limit: this.limit,
        search: this.queryData.search,
      });
    },
    makeStatsArr(statsObj) {
      const es = statsObj.errors_summary;
      const output = [];
      for (let action_name in es) {
        const stat = es[action_name];
        const stat_o = {
          name: `Action - ${action_name}`,
          children: [],
        };
        for (var err_name in stat) {
          const err_stat = {
            name: err_name,
            children: [
              { name: `Error message: ${err_name}` },
              { name: `Amount of errors: ${stat[err_name]}` },
            ],
          };
          stat_o.children.push(err_stat);
        }
        output.push(stat_o);
      }
      return output;
    },
    getStat({ id }) {
      this.$emit('get-stat', id);
    },
      deleteItem(id) {
          this.rows = this.rows.filter((row) => row.id !== id);
      },
      resumeAndDeleteWorkflow(id) {
          this.resumeWorkflow(id).then((_, err) => { if(!err) { this.deleteItem(id) } })
      },
      goToHistoryPage(item, failedTask) {
          const customer = this.customers.find((cstr) => cstr.customer_id === item.customer_id)
          this.setCustomer(customer)
          item.failedTask = failedTask;
          this.setWorkflowHistoryItem(item);
          this.$router.push({
              path: `/workflows/${item.workflow_id}/history?customer_id=${item.customer_id}`,
          })
      },
  },
  mounted() {
    this.limit = 10;
  },
  created() {
    this.limit = 10;
  }
}
</script>
